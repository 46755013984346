import React, { useState } from "react";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";

const Button = (props) => {
  //const url = "https://192.168.222.137/kekes/gym.php";
  //const url ="https://09ab-182-2-71-244.ngrok-free.app/gym/gym.php";
  const url = "https://monitoring.kekesgym.site/gym.php";
  const navigate = useNavigate();
  const [pilihanWaktu, setPilihanWaktu] = useState(null);
  //function kirim login
  const KirimLogin = () => {
    
    let passLogin = localStorage.getItem("passwordLogin");
    let pilihanLogin = localStorage.getItem("pilihanLogin");
    if(pilihanLogin===null){
      props.ValKirim("Silakan pilih dahulu");
    }else{
      fetch(url + "/?op=getAkun", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body:
          "pilihan_login=" +
          pilihanLogin +
          "&password=" +
          passLogin 
      })
      .then((response)=>response.json())
      .then((json)=>{
        if(json!==null){
  if(json === "loginGym"){
    navigate("/gym");
    localStorage.setItem("loginSebagai", "y");
   
  }else{
    navigate("/owner");
    localStorage.setItem("loginSebagai", "0wr");
  }
  localStorage.removeItem("passwordLogin");
  localStorage.removeItem("pilihanLogin");
        }else{
          props.ValKirim("Password Salah");
        }
      })
    }
    
    /*
    let passLogin = localStorage.getItem("passwordLogin");
    let pilihanLogin = localStorage.getItem("pilihanLogin");
    if (pilihanLogin === null) {
      props.ValKirim("Silakan pilih dahulu");
    } else if (pilihanLogin === "loginZumba" && passLogin === "akunZumba") {
      navigate("/zumba");
      localStorage.setItem("loginSebagai", "zm");
      localStorage.removeItem("passwordLogin");
      localStorage.removeItem("pilihanLogin");
    } else if (pilihanLogin === "loginGym" && passLogin === "gymKekesAkun") {
      navigate("/gym");
      localStorage.setItem("loginSebagai", "y");
      localStorage.removeItem("passwordLogin");
      localStorage.removeItem("pilihanLogin");
    } else if (pilihanLogin === "loginOwner" && passLogin === "ownerKekesLogin") {
      navigate("/owner");
      localStorage.setItem("loginSebagai", "0wr");
      localStorage.removeItem("passwordLogin");
      localStorage.removeItem("pilihanLogin");
    } else {
      props.ValKirim("Password Salah");
    }*/
  };

  // function kirim member gym baru
  const KirimMemberGymBaru = () => {
    let nama = localStorage.getItem("namaMemberGym");
    let alamat = localStorage.getItem("alamatMemberGym");
    let nomor = localStorage.getItem("nomorMemberGym");
    let waktu = localStorage.getItem("waktuMemberGym");
    let status = localStorage.getItem("statusMemberGym");
    let gender = localStorage.getItem("genderGym");
    let pembayaran = localStorage.getItem("pembayaranGym");
    let rfid = localStorage.getItem("rfidVisitorGym");

    let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth(); // Bulan dimulai dari 0
      let day = today.getDate();
      
      // Format tanggal hari ini
      let formattedDate = `${year}-${String(month + 1).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
      
      // Menentukan jumlah bulan yang ditambahkan berdasarkan waktu
      let tambahanBulan;
      if (waktu === "1 Bulan") {
        tambahanBulan = 1;
      } else if (waktu === "3 Bulan") {
        tambahanBulan = 3;
      } else if (waktu === "6 Bulan") {
        tambahanBulan = 6;
      } else if (waktu === "12 Bulan") {
        tambahanBulan = 12;
      }
      
      // Menghitung bulan dan tahun selanjutnya
      let totalBulan = month + tambahanBulan;
      let tahunSelanjutnya = year + Math.floor(totalBulan / 12);
      let bulanSelanjutnya = totalBulan % 12;
      
      // Membuat objek tanggal baru dengan bulan dan tahun yang telah disesuaikan
      let waktuSelanjutnya = new Date(tahunSelanjutnya, bulanSelanjutnya, day);
      
      // Memastikan tanggal valid (misalnya, 31 Januari + 1 bulan = 28 Februari/29 Februari)
      if (waktuSelanjutnya.getMonth() !== bulanSelanjutnya) {
        waktuSelanjutnya = new Date(tahunSelanjutnya, bulanSelanjutnya + 1, 0); // Set ke hari terakhir bulan sebelumnya
      }
      
      // Format tanggal hasil perhitungan
      let waktuExpiredYear = waktuSelanjutnya.getFullYear();
      let waktuExpiredMonth = String(waktuSelanjutnya.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
      let waktuExpiredDay = String(waktuSelanjutnya.getDate()).padStart(2, "0");
      
      let formattedWaktuExpired = `${waktuExpiredYear}-${waktuExpiredMonth}-${waktuExpiredDay}`;

    if (
      nama === null ||
      nama === "" ||
      alamat === null ||
      alamat === "" ||
      nomor === null ||
      nomor === "" ||
      status === null ||
      status === "Pilih"
    ) {
      props.ValKirim("kosong");
    } else if (status === "Member Umum" || status === "Member Pelajar") {
      if (
        nama === null ||
        nama === "" ||
        alamat === null ||
        alamat === "" ||
        nomor === null ||
        nomor === "" ||
        waktu === null ||
        waktu === "Pilih"||
        pembayaran === null ||
        pembayaran === "Pilih"
      ) {
        props.ValKirim("kosong");
      } else {
        fetch(url + "/?op=inputMemberBaruGym", {
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body:
            "rfid=" +
            rfid +
            "&nama=" +
            nama +
            "&alamat=" +
            alamat +
            "&nomor=" +
            nomor +
            "&status=" +
            status +
            "&waktuSaatIni=" +
            formattedDate +
            "&waktuExpired=" +
            formattedWaktuExpired +
            "&waktu=" +
            waktu+
            "&pembayaran=" +
            pembayaran
        })
          .then((response) => response.json())
          .then((json) => {
            if (json === "berhasil") {
              navigate("/gym/attendance");
              localStorage.removeItem("namaMemberGym");
              localStorage.removeItem("alamatMemberGym");
              localStorage.removeItem("nomorMemberGym");
              localStorage.removeItem("waktuMemberGym");
              localStorage.removeItem("statusMemberGym");
              localStorage.removeItem("pembayaranGym");
              localStorage.removeItem("rfidVisitorGym");
            }
          });
      }
    } else if (status === "Karyawan") {
      if (
        nama === null ||
        nama === "" ||
        alamat === null ||
        alamat === "" ||
        nomor === null ||
        nomor === ""
      ) {
        props.ValKirim("kosong");
      } else {
        fetch(url + "/?op=inputKaryawanBaruGym", {
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body:
            "rfid=" +
            rfid +
            "&nama=" +
            nama +
            "&alamat=" +
            alamat +
            "&nomor=" +
            nomor +
            "&status=" +
            status +
            "&waktuSaatIni=" +
            formattedDate,
        })
          .then((response) => response.json())
          .then((json) => {
            if (json === "berhasil") {
              navigate("/gym/attendance");
              localStorage.removeItem("namaMemberGym");
              localStorage.removeItem("alamatMemberGym");
              localStorage.removeItem("nomorMemberGym");
              localStorage.removeItem("waktuMemberGym");
              localStorage.removeItem("statusMemberGym");

              localStorage.removeItem("rfidVisitorGym");
            }
          });
      }
    } else if (status === "Trainer") {
      if (
        nama === null ||
        nama === "" ||
        alamat === null ||
        alamat === "" ||
        nomor === null ||
        nomor === "" ||
        gender === null ||
        gender === ""
      ) {
        props.ValKirim("kosong");
      } else {
        fetch(url + "/?op=inputTrainerBaruGym", {
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body:
            "rfid=" +
            rfid +
            "&nama=" +
            nama +
            "&alamat=" +
            alamat +
            "&nomor=" +
            nomor +
            "&status=" +
            status +
            "&waktuSaatIni=" +
            formattedDate+
            "&gender=" +
            gender
        })
          .then((response) => response.json())
          .then((json) => {
            if (json === "berhasil") {
              navigate("/gym/attendance");
              localStorage.removeItem("namaMemberGym");
              localStorage.removeItem("alamatMemberGym");
              localStorage.removeItem("nomorMemberGym");
              localStorage.removeItem("waktuMemberGym");
              localStorage.removeItem("statusMemberGym");
              localStorage.removeItem("genderGym");
              localStorage.removeItem("rfidVisitorGym");
            }
          });
      }
    } else {
      props.ValKirim("kosong");
    }
  };

  // function kirim gym perpanjangan
  const KirimGymPerpanjangan = () => {
    if (
      localStorage.getItem("waktuGymPerpanjangan") === null ||
      localStorage.getItem("waktuGymPerpanjangan") === "Pilih"||
      localStorage.getItem("pembayaranGymPerpanjangan") === null ||
      localStorage.getItem("pembayaranGymPerpanjangan") === "Pilih"
    ) {
      props.ValKirim("kosong");
    } else {
      let waktu = localStorage.getItem("waktuGymPerpanjangan");
      let rfid = localStorage.getItem("rfidVisitorGym");
      let pembayaran = localStorage.getItem("pembayaranGymPerpanjangan");
      let status=localStorage.getItem("statusGymPerpanjangan");
      //

      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth(); // Bulan dimulai dari 0
      let day = today.getDate();
      
      // Format tanggal hari ini
      let formattedDate = `${year}-${String(month + 1).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
      
      // Menentukan jumlah bulan yang ditambahkan berdasarkan waktu
      let tambahanBulan;
      if (waktu === "1 Bulan") {
        tambahanBulan = 1;
      } else if (waktu === "3 Bulan") {
        tambahanBulan = 3;
      } else if (waktu === "6 Bulan") {
        tambahanBulan = 6;
      } else if (waktu === "12 Bulan") {
        tambahanBulan = 12;
      }
      
      // Menghitung bulan dan tahun selanjutnya
      let totalBulan = month + tambahanBulan;
      let tahunSelanjutnya = year + Math.floor(totalBulan / 12);
      let bulanSelanjutnya = totalBulan % 12;
      
      // Membuat objek tanggal baru dengan bulan dan tahun yang telah disesuaikan
      let waktuSelanjutnya = new Date(tahunSelanjutnya, bulanSelanjutnya, day);
      
      // Memastikan tanggal valid (misalnya, 31 Januari + 1 bulan = 28 Februari/29 Februari)
      if (waktuSelanjutnya.getMonth() !== bulanSelanjutnya) {
        waktuSelanjutnya = new Date(tahunSelanjutnya, bulanSelanjutnya + 1, 0); // Set ke hari terakhir bulan sebelumnya
      }
      
      // Format tanggal hasil perhitungan
      let waktuExpiredYear = waktuSelanjutnya.getFullYear();
      let waktuExpiredMonth = String(waktuSelanjutnya.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
      let waktuExpiredDay = String(waktuSelanjutnya.getDate()).padStart(2, "0");
      
      let formattedWaktuExpired = `${waktuExpiredYear}-${waktuExpiredMonth}-${waktuExpiredDay}`;

      fetch(url + "/?op=inputGymPerpanjangan", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body:
          "rfid=" +
          rfid +
          "&waktuSaatIni=" +
          formattedDate +
          "&waktuExpired=" +
          formattedWaktuExpired+
          "&pembayaran=" +
          pembayaran+
          "&status=" +
          status+
          "&waktu=" +
          waktu
      })
        .then((response) => response.json())
        .then((json) => {
          
          if (json === "berhasil") {
            navigate("/gym/attendance");
            localStorage.removeItem("waktuGymPerpanjangan");
            localStorage.removeItem("rfidVisitorGym");
            localStorage.removeItem("pembayaranGymPerpanjangan");
            localStorage.removeItem("statusGymPerpanjangan");
            localStorage.removeItem("startGymPerpanjangan");
            localStorage.removeItem("expiredGymPerpanjangan");
          }
        });
    }
  };

  //function kirim daftar personal trainer

  // function kirim member zumba karyawan atau trainer

  const KirimMemberZumbaBaru = () => {
    let nama = localStorage.getItem("namaMemberZumba");
    let alamat = localStorage.getItem("alamatMemberZumba");
    let nomor = localStorage.getItem("nomorMemberZumba");

    let status = localStorage.getItem("statusMemberZumba");

    let rfid = localStorage.getItem("rfidVisitorZumba");
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth(); // Bulan dimulai dari 0
    let day = today.getDate();

    // Format tanggal hari ini
    let formattedDate = `${year}-${String(month + 1).padStart(2, "0")}-${String(
      day
    ).padStart(2, "0")}`;

    if (status === "karyawan" || status === "trainer") {
      if (
        nama === null ||
        nama === "" ||
        alamat === null ||
        alamat === "" ||
        nomor === null ||
        nomor === ""
      ) {
        props.ValKirim("kosong");
      } else {
        fetch(url + "/?op=inputKaryawanAtauTrainerBaruZumba", {
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body:
            "rfid=" +
            rfid +
            "&nama=" +
            nama +
            "&alamat=" +
            alamat +
            "&nomor=" +
            nomor +
            "&status=" +
            status +
            "&waktuSaatIni=" +
            formattedDate,
        })
          .then((response) => response.json())
          .then((json) => {
            if (json === "berhasil") {
              navigate("/zumba/attendance");

              localStorage.removeItem("namaMemberZumba");
              localStorage.removeItem("alamatMemberZumba");
              localStorage.removeItem("nomorMemberZumba");
              localStorage.removeItem("statusMemberZumba");
              localStorage.removeItem("rfidVisitorZumba");
            }
          });
      }
    } else {
      props.ValKirim("kosong");
    }
  };

  //function kirim personal trainer
  const KirimPersonalTrainer = () => {
    let nama_peserta = localStorage.getItem("namaPeserta");
    let alamat = localStorage.getItem("alamatPeserta");
    let nomor_hp = localStorage.getItem("nomorHpPeserta");
    let berat_badan = localStorage.getItem("beratBadanPeserta");
    let goal = localStorage.getItem("goalPeserta");
    let paket_pertemuan = localStorage.getItem("paketPertemuan");
    let pembayaran = localStorage.getItem("pembayaranTrainer");
    if (paket_pertemuan !== null) {
      if (paket_pertemuan !== "Pilih") {
        paket_pertemuan = paket_pertemuan.split(" ");
        paket_pertemuan = paket_pertemuan[0];
      }
    }
    let status_pertemuan = localStorage.getItem("statusPertemuan");
    let pilihan_trainer = localStorage.getItem("pilihanTrainer");
    let keterangan = localStorage.getItem("keterangan");
    if (
      nama_peserta === null ||
      nama_peserta === "" ||
      alamat === null ||
      alamat === "" ||
      nomor_hp === null ||
      nomor_hp === "" ||
      berat_badan === null ||
      berat_badan === "" ||
      goal === null ||
      goal === "" ||
      paket_pertemuan === null ||
      paket_pertemuan === "Pilih" ||
      status_pertemuan === null ||
      status_pertemuan === "Pilih" ||
      pilihan_trainer === null ||
      pilihan_trainer === "Pilih" ||
      pembayaran === null ||
      pembayaran === "Pilih" ||
      (pilihan_trainer === "Cewek" &&
        (keterangan === null || keterangan === ""))
    ) {
      props.ValKirim("kosong");
    } else {
      if(pilihan_trainer === "Perpanjangan"){
       
        
        fetch(url + "/?op=inputPerpanjanganPersonalTrainer", {
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body:
            "nama_peserta=" +
            nama_peserta +
            "&alamat=" +
            alamat +
            "&alamat=" +
            alamat +
            "&nomor_hp=" +
            nomor_hp +
            "&berat_badan=" +
            berat_badan +
            "&goal=" +
            goal +
            "&paket_pertemuan=" +
            paket_pertemuan +
            "&status_pertemuan=" +
            status_pertemuan +
            "&pilihan_trainer=" +
            "perpanjangan" +
            "&keterangan=" +
            keterangan+
            "&metode=" +
            pembayaran,
        })
          .then((response) => response.json())
          .then((json) => {
            if (json !== null) {
            
              
              fetch(url + "/?op=getTrainerReader")
                .then((response) => response.json())
                .then((json) => {
                  if (json !== null) {
                    props.ValKirim(json);
                  }
                });
            }
          });
      }else{
        fetch(url + "/?op=inputPersonalTrainer", {
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body:
            "nama_peserta=" +
            nama_peserta +
            "&alamat=" +
            alamat +
            "&alamat=" +
            alamat +
            "&nomor_hp=" +
            nomor_hp +
            "&berat_badan=" +
            berat_badan +
            "&goal=" +
            goal +
            "&paket_pertemuan=" +
            paket_pertemuan +
            "&status_pertemuan=" +
            status_pertemuan +
            "&pilihan_trainer=" +
            pilihan_trainer +
            "&keterangan=" +
            keterangan+
            "&metode=" +
            pembayaran,
        })
          .then((response) => response.json())
          .then((json) => {
            if (json !== null) {
              fetch(url + "/?op=getTrainerReader")
                .then((response) => response.json())
                .then((json) => {
                  if (json !== null) {
                    props.ValKirim(json);
                  }
                });
            }
          });
      }
    
    }
  };

  // function kirim pembyaran freelance
  const KirimPembayaranFreelance=()=>{
    if((localStorage.getItem("pembayaranFreelance")===null)||
    (localStorage.getItem("pembayaranFreelance")==="Pilih")){
props.ValKirim("kosong");
    }else{
      props.ValKirim("isi");
    }
  }

  //function Klik button
  const Klik = (id) => {
    switch (id) {
      case "kirimLogin":
        KirimLogin();

        break;
      case "kirimMemberGymBaru":
        KirimMemberGymBaru();
        break;
      case "kirimMemberZumbaBaru":
        KirimMemberZumbaBaru();
        break;
      case "kirimGymPerpanjangan":
        KirimGymPerpanjangan();
        break;
      case "kirimPersonalTrainer":
        KirimPersonalTrainer();
        break;
        case "kirimPembayaranFreelance":
        KirimPembayaranFreelance();
        break;
      default:
        break;
    }
  };
  return (
    <button
      onClick={() => Klik(props.id)}
      style={{ borderRadius: props.borderRadius }}
      className={style.containerAll}
    >
      {props.nama}
    </button>
  );
};
export default Button;
