import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";
//import LogoKekes from "../../Photo/logoKekes.png";
import LogoKekes from "../../Photo/logoKekes2.png";
import FotoGym1 from "../../Photo/gym1.png";
import FotoGym2 from "../../Photo/gym2.png";
import FotoGym3 from "../../Photo/gym3.png";
import FotoGym4 from "../../Photo/gym4.png";
import FotoGym5 from "../../Photo/gym5.png";
import FotoGym6 from "../../Photo/gym6.png";
import SpinnerForm from "../../Component/SpinnerForm";
import Button from "../../Component/Button";
import LogoSilang from "../../Photo/logoSilang.png";

const AttendanceGym = (props) => {
  const navigate = useNavigate();
  const url = props.url;
  const inputRef = useRef(null);
  const [valueInput, setValueInput] = useState("");
  const [keteranganPembayaranFreelance, setKeteranganPembayaranFreelance] =
    useState("");
  const [valueHasilNama, setValueHasilNama] = useState("");
  const [valueHasilDate, setValueHasilDate] = useState("");
  const [prosesScan, setProsesScan] = useState(true);
  const [tulisanSudahScanAtas, setTulisanSudahScanAtas] = useState("");
  const [tulisanSudahScanBawah, setTulisanSudahScanBawah] = useState("");
  const images = [FotoGym1, FotoGym2, FotoGym3, FotoGym4, FotoGym5, FotoGym6];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pembayaranFreelance, setPembayaranFreelance] = useState(
    "notVisiblePembayaranFreelance"
  );
  const[focus,setFocus]=useState(true);
  const spinnerRef1 = useRef();

  //function input freelance
  const InputFreelance=()=>{
    fetch(url + "/?op=inputFreelance", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "rfid=" + localStorage.getItem("rfidVisitorGym")+"&pembayaran="+
      localStorage.getItem("pembayaranFreelance"),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json === "tidak ada koneksi") {
          setProsesScan(false);
          setTulisanSudahScanAtas("Ups Koneksi internet tidak ada :(");
        } else {
          spinnerRef1.current.clearValue();
          localStorage.removeItem("rfidVisitorGym");
          localStorage.removeItem("pembayaranFreelance");
          setPembayaranFreelance("notVisiblePembayaranFreelance");
          setKeteranganPembayaranFreelance("");
          setProsesScan(false);
          setTulisanSudahScanAtas("Keep Healthy :)");
          setTulisanSudahScanBawah(json);
        }
        setValueInput("");

        // Clear the input after 1000 ms
        setTimeout(() => {
          setProsesScan(true);
          setFocus(true);
   
        }, 2000);
      });
  }
  // Function to handle input change
  const handleInputChange = (val) => {
    setValueInput(val);
    let today = new Date();
    let year = today.getFullYear();
    let month = String(today.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
    let day = String(today.getDate()).padStart(2, "0");

    let formattedDate = `${year}-${month}-${day}`;
    //setValueHasilDate(formattedDate);

    // Check if the input length is 10
    if (/[^0-9]/.test(val)) {
      setValueHasilNama("Kartu Error");
      setValueInput("");
      setTimeout(() => {
        setValueHasilNama("");
      }, 1000);
    }
    else if(val.length === 10){
      fetch(url + "/?op=getRfidFreelance", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: "rfid=" + val 
      })
      .then((response)=>response.json())
      .then((json)=>{
        if(json === "freelance"){
          setFocus(false);
          localStorage.setItem("rfidVisitorGym", val);
          setPembayaranFreelance("visiblePembayaranFreelance");
        }else if(json === "tidak ada koneksi"){
          setProsesScan(false);
          setTulisanSudahScanAtas("Ups Koneksi internet tidak ada :(");
          setValueInput("");

          // Clear the input after 1000 ms
          setTimeout(() => {
            setProsesScan(true);
          }, 2000);
        
        }
        else{
          localStorage.setItem("rfidVisitorGym", val);
      fetch(url + "/?op=getNamaScan", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: "rfid=" + val + "&date=" + formattedDate, // Assuming 'val' contains the RFID value
      })
        .then((response) => response.json())
        .then((json) => {
        
          if (json === "tidak ada koneksi") {
            setProsesScan(false);
            setTulisanSudahScanAtas("Ups Koneksi internet tidak ada :(");
          } else if (json === "data tidak ditemukan") {
            navigate("/gym/attendance/memberBaru");
          }
          else{
            if(json[0] === "expired") {
              localStorage.setItem("namaGymPerpanjangan", json[2]);
              localStorage.setItem("alamatGymPerpanjangan", json[3]);
              localStorage.setItem("nomorHpGymPerpanjangan", json[4]);
              localStorage.setItem("startGymPerpanjangan", json[5]);
              localStorage.setItem("expiredGymPerpanjangan", json[6]);
              localStorage.setItem("statusGymPerpanjangan", json[7]);
              navigate("/gym/attendance/memberPerpanjangan");
            }else{
              setProsesScan(false);
              setTulisanSudahScanAtas("Keep Healthy :)");
              setTulisanSudahScanBawah(json);
            }
          } 

          setValueInput("");

          // Clear the input after 1000 ms
          setTimeout(() => {
            setProsesScan(true);
          }, 2000);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
        }
      })
    }
    /* else if (val === "0014397782" || val === "0014605980") {
      
      setFocus(false);
      localStorage.setItem("rfidVisitorGym", val);
      setPembayaranFreelance("visiblePembayaranFreelance");
    
    } else if (
      (val.length === 10 && val !== "0014397782") ||
      (val.length === 10 && val !== "0014605980")
    ) {
      localStorage.setItem("rfidVisitorGym", val);
      fetch(url + "/?op=getNamaScan", {
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: "rfid=" + val + "&date=" + formattedDate, // Assuming 'val' contains the RFID value
      })
        .then((response) => response.json())
        .then((json) => {
        
          if (json === "tidak ada koneksi") {
            setProsesScan(false);
            setTulisanSudahScanAtas("Ups Koneksi internet tidak ada :(");
          } else if (json === "data tidak ditemukan") {
            navigate("/gym/attendance/memberBaru");
          }
          else{
            if(json[0] === "expired") {
              localStorage.setItem("namaGymPerpanjangan", json[2]);
              localStorage.setItem("alamatGymPerpanjangan", json[3]);
              localStorage.setItem("nomorHpGymPerpanjangan", json[4]);
              localStorage.setItem("startGymPerpanjangan", json[5]);
              localStorage.setItem("expiredGymPerpanjangan", json[6]);
              localStorage.setItem("statusGymPerpanjangan", json[7]);
              navigate("/gym/attendance/memberPerpanjangan");
            }else{
              setProsesScan(false);
              setTulisanSudahScanAtas("Keep Healthy :)");
              setTulisanSudahScanBawah(json);
            }
          } 

          setValueInput("");

          // Clear the input after 1000 ms
          setTimeout(() => {
            setProsesScan(true);
          }, 2000);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }*/
  };

  //function jika belum scan
  const BelumScan = () => {
    return (
      <div className={style.divKotakTap}>
        <input
          placeholder="Tap your card please :)"
          value={valueHasilNama}
          readOnly={true}
          className={style.inputField}
        />
      </div>
    );
  };

  //function jika sudah scan
  const SudahScan = () => {
    return (
      <div className={style.divSudahScan}>
        <div className={style.sudahScanAtas}>{tulisanSudahScanAtas}</div>
        <div className={style.sudahScanBawah}>{tulisanSudahScanBawah}</div>
      </div>
    );
  };
// klik logo silang pembayaran freelance
const KlikLogoSilang=()=>{
  spinnerRef1.current.clearValue();
  setProsesScan(true);
      setFocus(true);
      localStorage.removeItem("rfidVisitorGym");
      setPembayaranFreelance("notVisiblePembayaranFreelance");
      setKeteranganPembayaranFreelance("");
      setValueInput("");
      localStorage.removeItem("pembayaranFreelance");
}
  // function kirim ketika tap kartu freelace
  const ValKirim = (val) => {
    if(val==="kosong"){
      setKeteranganPembayaranFreelance("Pilih dahulu");
    }else{
      InputFreelance();
    }
  };
  //use effect
  useEffect(() => {
    // Initially focus the input field
    if (inputRef.current) {
      inputRef.current.focus();
    }

    // Set an interval to refocus the input field every 100 ms
    const interval = setInterval(() => {
      if (inputRef.current &&focus) {
        inputRef.current.focus();
      }
    }, 100);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [focus]);

  // use effect hapus
  useEffect(() => {
    localStorage.removeItem("rfidVisitorGym");
    localStorage.removeItem("pembayaranFreelance");
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Ganti angka 5000 dengan interval yang diinginkan (dalam milidetik)

    return () => clearInterval(interval); // Membersihkan interval saat komponen unmount
  }, [images.length]);

  return (
    <div className={style.containerAll}>
      <div className={style[`${pembayaranFreelance}`]}>
        <div className={style.judulPembayaranFreelance}>
          Pembayaran Freelance
          <img onClick={()=>KlikLogoSilang()} className={style.logoSilang} src={LogoSilang}/>
        </div>
        <div className={style.spinnerPembayaranFreelance}>
          <SpinnerForm ref={spinnerRef1} nama="Pembayaran" id="pembayaranFreelance" />
        </div>
        <div className={style.keteranganPembayaranFreelance}>
          {keteranganPembayaranFreelance}
        </div>
        <div className={style.buttonPembayaranFreelance}>
          <Button
            ValKirim={ValKirim}
            borderRadius="2vw"
            nama="Kirim"
            id="kirimPembayaranFreelance"
          />
        </div>
      </div>

      <img className={style.gantiFoto} src={images[currentIndex]} />
      <input
        className={style.inputBayangan}
        value={valueInput}
        onChange={(e) => handleInputChange(e.target.value)}
        ref={inputRef}
      />
      <div className={style.tulisanAttendance}>
        <div onClick={() => navigate("/gym")}>
          <img src={LogoKekes} />
        </div>
        Attendance Gym
      </div>
      <div className={style.divIsi}></div>
      {prosesScan === true ? BelumScan() : SudahScan()}
    </div>
  );
};

export default AttendanceGym;
